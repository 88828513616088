<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components';
import debounce from 'lodash/debounce';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';

import { useDropdown } from '../composables/useDropdown';
import type { JamBaseSelectOption } from './JamBaseSelect.vue';

const props = defineProps({
    disabled: {
        default: false,
        type: Boolean,
    },
    enterCallback: {
        default: null,
        type: Function as PropType<
            (searchTerm: string) => Promise<void>
        > | null,
    },
    error: {
        default: null,
        type: String,
    },
    hasLeadingSearchIcon: {
        default: false,
        type: Boolean,
    },
    highlighted: {
        default: false,
        type: Boolean,
    },
    icon: {
        default: null,
        type: String,
    },
    ignoreEnterOnPrefix: {
        default: null,
        type: String,
    },
    info: {
        default: null,
        type: String,
    },
    infoLabel: {
        default: 'Hinweis',
        type: String,
    },
    label: {
        default: null,
        type: String,
    },
    modelValue: {
        default: null,
        type: [String, Number],
    },
    onSelectOption: {
        default: null,
        type: Function as PropType<
            (selectedOption: JamBaseSelectOption) => void
        >,
    },
    options: {
        default: null,
        type: Array as () => JamBaseSelectOption[],
    },
    optionsCallback: {
        default: null,
        type: Function as PropType<
            (searchValue: string) => Promise<JamBaseSelectOption[]>
        > | null,
    },
    placeholder: {
        default: 'Bitte auswählen',
        type: String,
    },
    selectedLabel: {
        default: '',
        type: String,
    },
});

const emit = defineEmits(['update:modelValue', 'update:selectedLabel']);

const id = useId();
const searchValue = ref<string>(props.selectedLabel);
const opened = ref<boolean>(false);
const highlightedOption = ref<string | number | null>();
const localValue = ref<string | number | null>(props.modelValue);
const localValueLabel = ref<string>(props.selectedLabel);
const filteredOptionsRef = ref<string>(props.options || null);

const targetElement = ref<HTMLElement | null>(null);
const dropdownElement = ref<HTMLElement | null>(null);
const { cancelDropdownPositionWatcher, initDropdownPositionWatcher, openTop } =
    useDropdown(targetElement, dropdownElement);

onMounted(() => {
    localValue.value = props.modelValue;
});

const keyboardNavigation = (event: KeyboardEvent) => {
    if (!filteredOptions.value) return;

    if (
        event.key === 'ArrowUp' ||
        event.key === 'ArrowDown' ||
        event.key === 'Enter'
    ) {
        event.preventDefault();

        const index = filteredOptions.value.findIndex(
            (option) => option.value === highlightedOption.value,
        );

        if (event.key === 'ArrowDown') {
            if (index >= -1 && index < filteredOptions.value.length - 1) {
                highlightedOption.value =
                    filteredOptions.value[index + 1].value;
            } else if (index >= filteredOptions.value.length - 1) {
                highlightedOption.value = filteredOptions.value[0].value;
            }
        }

        if (event.key === 'ArrowUp') {
            if (index > 0) {
                highlightedOption.value =
                    filteredOptions.value[index - 1].value;
            } else if (index <= 0) {
                highlightedOption.value =
                    filteredOptions.value[
                        filteredOptions.value.length - 1
                    ].value;
            }
        }

        // Select highlighted option on Enter
        if (event.key === 'Enter') {
            selectOption(filteredOptions.value[index]);
        }
    }

    // Close on Escape or Tab
    if (event.key === 'Escape' || event.key === 'Tab') {
        handleBlur();
    }
};

const open = () => {
    filteredOptionsRef.value = props.options || null;
    opened.value = true;

    document.addEventListener('keydown', keyboardNavigation);
    initDropdownPositionWatcher();
};

const close = () => {
    opened.value = false;

    document.removeEventListener('keydown', keyboardNavigation);
    cancelDropdownPositionWatcher();
};

const selectOption = (option: JamBaseSelectOption) => {
    if (!option?.value) {
        return;
    }
    localValue.value = option.value;
    localValueLabel.value = option.label;
    searchValue.value = option.label;
    close();

    if (
        !searchValue.value.startsWith(props.ignoreEnterOnPrefix) &&
        props.onSelectOption
    ) {
        props.onSelectOption(option);
    }
};

const clear = () => {
    localValue.value = props.hasLeadingSearchIcon ? '' : null;
    localValueLabel.value = '';
    searchValue.value = '';
    close();
};

const handleBlur = async () => {
    if (searchValue.value !== localValueLabel.value) {
        clear();
    } else {
        close();
    }
};

const executeOptionsCallback = () => {
    if (typeof props.optionsCallback !== 'function') {
        return;
    }

    if (
        props.ignoreEnterOnPrefix &&
        searchValue.value.startsWith(props.ignoreEnterOnPrefix)
    ) {
        const fakeOption = {
            label: searchValue.value,
            value: searchValue.value,
        };
        filteredOptionsRef.value = [fakeOption];

        selectOption(fakeOption);
        return;
    }
    props.optionsCallback(searchValue.value).then((options) => {
        filteredOptionsRef.value = options;
    });
};
const debouncedExecuteOptionsCallback = debounce(executeOptionsCallback, 300);

const filteredOptions = computed(() => {
    return filteredOptionsRef.value || null;
});

watch(searchValue, () => {
    // Open dropdown if search input is not empty and value is different from local value
    if (searchValue.value !== localValueLabel.value) {
        open();
    }

    if (props.options) {
        filteredOptionsRef.value = props.options.filter((option) =>
            option.label
                .toLowerCase()
                .includes(searchValue.value.toLowerCase()),
        );
    }

    if (typeof props.optionsCallback === 'function') {
        debouncedExecuteOptionsCallback();
    }
});

watch(
    () => props.modelValue,
    () => {
        localValue.value = props.modelValue;
    },
);
watch(
    () => props.selectedLabel,
    () => {
        searchValue.value = props.selectedLabel;
        localValueLabel.value = props.selectedLabel;
    },
);

watch(localValue, () => {
    const newLabel = filteredOptions.value?.find(
        (option) => option.value === localValue.value,
    )?.label;
    if (newLabel) {
        localValueLabel.value = newLabel;
    }
    emit('update:modelValue', localValue.value);
    emit('update:selectedLabel', localValueLabel.value);
});

const searchIcon = computed(() => {
    return {
        background:
            "transparent url(\"data:image/svg+xml,%3Csvg height='18px' xmlns='http://www.w3.org/2000/svg' viewBox='4 3 16 17'%3E%3Cpath d='M16.266 10.663a5.663 5.663 0 1 1-11.327 0 5.663 5.663 0 0 1 11.327 0Zm-1.659 4.004L19.059 19' stroke='%2359667B' fill='none' stroke-linecap='round'/%3E%3C/svg%3E\") no-repeat 13px center",
    };
});
const onPressEnter = async (event: KeyboardEvent) => {
    if (opened.value) {
        return;
    }
    if (props.enterCallback && event.key === 'Enter') {
        await props.enterCallback(searchValue.value);
    }
};

onMounted(() => {
    document.addEventListener('keypress', onPressEnter);
});

onUnmounted(() => {
    document.removeEventListener('keypress', onPressEnter);
});
</script>

<template>
    <div role="listbox" :data-invalid-field="error || null">
        <div
            v-if="label || info"
            class="mb-2 flex items-end justify-between gap-4"
        >
            <label :for="id" class="truncate">
                <JamBaseText
                    v-if="label"
                    class="text-gray-600"
                    variant="small"
                    :title="label"
                    :is-label="true"
                >
                    {{ label }}
                </JamBaseText>
            </label>
            <div class="shrink-0">
                <JamBaseTooltip v-if="info || $slots.info">
                    <template #info><slot name="info" />{{ info }}</template>
                    <JamBaseText
                        class="cursor-help border-b border-dashed border-gray-600 text-gray-600 hover:text-gray-900"
                        variant="small"
                    >
                        {{ infoLabel }}
                    </JamBaseText>
                </JamBaseTooltip>
            </div>
        </div>
        <div
            v-on-click-outside="handleBlur"
            class="relative flex items-center gap-2 rounded border border-gray-300 bg-white has-[input:disabled]:cursor-not-allowed has-[input:active]:border-gray-900 has-[input:focus]:border-gray-900 has-[input:invalid]:border-red-700 has-[input:disabled]:opacity-40 has-[input:focus-visible]:outline has-[input:focus-visible]:outline-2 has-[input:focus-visible]:outline-offset-2 has-[input:focus-visible]:outline-blue-600"
            :class="[
                error && error !== '' && 'border-red-700',
                highlighted && 'border-yellow-600',
            ]"
        >
            <JamBaseIcon
                v-if="icon"
                :icon-name="icon"
                stroke="thick"
                class="ml-3 text-gray-600"
            />

            <input
                :id="id"
                ref="targetElement"
                v-model="searchValue"
                type="text"
                :disabled="disabled"
                :placeholder="placeholder"
                autocomplete="off"
                class="w-full bg-white p-4 text-gray-900 focus:outline-none focus-visible:outline-none"
                :class="[
                    searchValue.length > 0 ? 'mr-6' : '',
                    hasLeadingSearchIcon && 'pl-[36px]',
                    icon !== null ? 'pl-2' : '',
                ]"
                :style="hasLeadingSearchIcon && searchIcon"
                @input="open"
                @click="open"
            />

            <ul
                v-if="opened"
                ref="dropdownElement"
                class="absolute inset-y-0 left-0 z-10 flex h-fit max-h-[400px] w-full flex-col overflow-auto rounded border border-gray-300 bg-white transition-all"
                :class="
                    openTop ? 'bottom-full -translate-y-full' : 'top-full mt-1'
                "
                role="list"
            >
                <template v-if="filteredOptions">
                    <li
                        v-for="(item, index) in filteredOptions"
                        :key="item.value as string"
                        role="listitem"
                    >
                        <span
                            v-if="
                                item.category &&
                                filteredOptions.findIndex(
                                    (i, idx) =>
                                        i.category === item.category &&
                                        idx < index,
                                ) === -1
                            "
                            class="mx-3 block border-b border-gray-100 py-3"
                        >
                            <JamBasePill
                                size="small"
                                class="w-full !justify-center"
                            >
                                {{ item.category }}
                            </JamBasePill>
                        </span>
                        <button
                            class="flex w-full items-center justify-between gap-4 p-3 transition-colors"
                            :class="{
                                'bg-gray-50': highlightedOption === item.value,
                            }"
                            tabindex="-1"
                            type="button"
                            @mouseover="highlightedOption = item.value"
                            @mouseleave="highlightedOption = null"
                            @click="selectOption(item)"
                        >
                            <span class="text-start text-gray-600">
                                {{ item.label }}
                            </span>
                            <JamBasePill
                                v-if="item.badgePill"
                                variant="blue"
                                size="small"
                            >
                                {{ item.badgePill }}
                            </JamBasePill>
                        </button>
                    </li>
                    <li v-if="(filteredOptions?.length ?? 0) === 0" class="p-3">
                        Keine Ergebnisse gefunden.
                    </li>
                </template>
                <template v-else-if="searchValue.length > 0">
                    <ElSkeleton animated>
                        <template #template>
                            <div class="p-3">
                                <el-skeleton-item variant="text" />
                                <el-skeleton-item variant="text" />
                                <el-skeleton-item variant="text" />
                            </div>
                        </template>
                    </ElSkeleton>
                </template>
            </ul>
            <button
                v-if="searchValue.length > 0 && !disabled"
                class="absolute inset-y-0 right-0 flex items-center px-4 text-gray-700"
                title="Eingabefeld leeren"
                type="button"
                @click="clear"
            >
                <JamBaseIcon icon-name="close" size="medium" />
            </button>
        </div>
        <JamBaseText v-if="error" variant="small" class="mt-2 text-red-700">
            {{ error }}
        </JamBaseText>
    </div>
</template>
